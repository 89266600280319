import { MutableRefObject } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import useGridSettingsPopover from "./useGridSettingsPopover";
import { DialogBox, IconDropdown } from "components";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import ColumnSettingsModal from "./ColumnSettingsModal";
import SavedGridSettingsDialog from "./SavedGridSettingsDialog";
import SaveGridSettingsDialog from "./SaveGridSettingsDialog";
import { FormikProvider } from "formik";
import { GRID_TYPE, pxToRem } from "appConstants";

type GridSettingsPopoverPropType = {
  gridApiRef: MutableRefObject<GridApiPremium | null>;
  gridType: GRID_TYPE;
};

const GridSettingsPopover: React.FC<GridSettingsPopoverPropType> = (props) => {
  const { children, gridApiRef, gridType } = props;

  const {
    menuData,
    isOpen,
    isSaveGridSettingsOpen,
    isSavedGridSettingsOpen,
    gridSettingsForm,
    showSelectionIndicator,
    toggleModal,
    toggleSaveGridSettingsModal,
    toggleSavedGridSettingsModal,
    handleGridSettingSelection,
  } = useGridSettingsPopover(gridApiRef, gridType);

  return (
    <FormikProvider value={gridSettingsForm}>
      <IconDropdown
        menuData={menuData}
        menuIcon={children ?? <SettingsIcon sx={{ mr: "0 !important" }} />}
        buttonProps={{
          sx: {
            backgroundColor: "greyVariantAlt.200",
            position: "relative",
            ...(showSelectionIndicator && {
              "&:after": {
                content: '""',
                position: "absolute",
                top: pxToRem(4),
                right: pxToRem(4),
                width: pxToRem(4),
                height: pxToRem(4),
                backgroundColor: "error.main",
                borderRadius: "50%",
              },
            }),
          },
        }}
      />
      <DialogBox
        open={isOpen}
        onClose={toggleModal}
        size={"lg"}
        sx={{ ".MuiPaper-root": { padding: "0 !important" } }}
      >
        <ColumnSettingsModal onClose={toggleModal} gridApiRef={gridApiRef} />
      </DialogBox>

      <DialogBox
        open={isSaveGridSettingsOpen}
        onClose={toggleSaveGridSettingsModal}
        size={"sm"}
      >
        <SaveGridSettingsDialog
          onClose={toggleSaveGridSettingsModal}
          gridApiRef={gridApiRef}
        />
      </DialogBox>

      <DialogBox
        open={isSavedGridSettingsOpen}
        onClose={toggleSavedGridSettingsModal}
        size={"sm"}
      >
        <SavedGridSettingsDialog
          onClose={toggleSavedGridSettingsModal}
          gridApiRef={gridApiRef}
          gridType={gridType}
          onSelection={handleGridSettingSelection}
        />
      </DialogBox>
    </FormikProvider>
  );
};

export default GridSettingsPopover;
