import { GRID_SETTINGS_FIELDS } from "appConstants/FormFields";
import { useFormikContext } from "formik";
import useText from "utils/hooks/useText";

const { SETTINGS_NAME, IS_DEFAULT } = GRID_SETTINGS_FIELDS;

const useSaveGridSettingsDialog = () => {
  const { values, isValid, handleChange, handleSubmit } = useFormikContext();

  const { cancel, save } = useText("buttons");
  const { configurationNameLabel, saveAsDefaultLabel } =
    useText("gridSettings");

  const settingsNameValue = values[SETTINGS_NAME];
  const saveAsDefaultValue = values[IS_DEFAULT];

  return {
    configurationNameLabel,
    saveAsDefaultLabel,
    cancel,
    save,
    settingsNameValue,
    saveAsDefaultValue,
    isValid,
    handleChange,
    handleSubmit,
  };
};

export default useSaveGridSettingsDialog;
