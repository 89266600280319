// Base URL
export const BASE_URL = process.env.REACT_APP_BASE_URL;

// Auth URLs
export const LOGIN_URL = "/api/Authenticate/authenticate";
export const LOGIN_AND_GET_REFRESH_TOKEN_URL =
  "/api/Authenticate/authenticatewithrefresh";
export const LOGIN_WITH_REFRESH_TOKEN_URL =
  "/api/Authenticate/refreshauthentication";
export const FORGOT_PASSWORD_URL = "/api/Account/forgotpassword";
export const RESET_PASSWORD_CODE_URL = "/api/Account/resetpassword";
export const LOGIN_WITH_GOOGLE_URL = "/api/Authenticate/verify-google-token";
export const LOGIN_WITH_MICROSOFT_URL =
  "/api/Authenticate/verify-microsoft-token";
export const GET_USER_INFO_URL = "/api/Account/user/info";
// INFO: This is added to create an action of logout to reset all state
export const LOGOUT_URL = "api/Account/logout";

// Role URLs
export const GET_ALL_ROLES_URL = "/api/Roles/allroles";
export const GET_ROLE_URL = "/api/Roles/getrolebyname";
export const CREATE_ROLE_URL = "/api/Roles/rolecreate";
export const EDIT_ROLE_URL = "/api/Roles/roleedit";
export const ASSIGN_ROLE_URL = "/api/Roles/updateuserrole";

// Personnel URLs
export const GET_ALL_PERSONNEL_URL = "/api/Account/users";
export const GET_PERSONNEL = "/api/Account/getuserbyid";
export const PERSONNEL_BULK_IMPORT_URL = "/api/Account/bulk-upload";
export const CREATE_PERSONNEL_URL = "/api/Account/createuser";
export const EDIT_PERSONNEL_URL = "/api/Account/updateuser";
export const MERGE_PERSONNEL = "/api/Roles/copyrole";
export const GET_COUNTRIES = "/api/Account/countries";
export const GET_REGIONS_URL = "/api/Account/regions";
export const GET_STATES_URL = "/api/Account/states";
export const GET_STATES_BY_COUNTRY_URL = "/api/Account/statesbycountry";
export const GET_STATES_BY_REGION_URL = "/api/Account/statesbyregion";
export const GET_COUNTIES = "/api/Account/counties";
export const GET_DEPARTMENTS = "/api/Department";
export const GET_JOB_TITLES = "/api/JobTitle";
export const GET_MANAGERS = "/api/Account/managers";
export const NOTIFY_USER_EMAIL = "/api/Account/notifyuseremail";
export const GET_CURRENT_USER = "/api/Account/user/self";
export const GET_PERSONNELS = "/api/Account/user/employee";

// get location API
export const GET_LOCATIONS = "/api/Account/alllocations";
export const GET_PORTERS = "/api/Account/user/porter";
export const GET_SALES_PERSONNELS = "/api/Account/user/sales";

// Permissions API
export const GET_ALL_PERMISSIONS_URL = "/api/Permissions/allpermissions";

//#region Inventory API

//#region Asset API
export const ASSET_URL = "/api/Asset";
export const GET_SUGGESTED_INVENTORY = "/api/Asset/suggested-asset";
export const GET_ASSET_HISTORY_DETAILS_URL = "/api/Asset/getassethistorydetail";
export const GET_ASSET_HISTORY_URL = "/api/Asset/assethistory";
export const GET_ASSET_BY_STOCK_URL = "/api/Asset/fetchassetbystocknumber";
export const CREATE_DUPLICATE_ASSET = "/api/Asset/duplicateasset";
//#endregion

//#region Template API
export const GET_ALL_TEMPLATES_URL = "/api/Asset/templates";
export const TEMPLATE_URL = "/api/Asset/template";
export const GET_ASSET_TEMPLATE_HISTORY_BY_ID_URL =
  "/api/AssetTemplate/getassettemplatehistory";
//#endregion

export const GET_ALL_TITLE_STATUS_URL = "/api/TitleStatus/fetchtitleStatus";
export const GET_ALL_CYLINDER_URL = "/api/CylinderType/fetchcylinderTypes";
export const GET_ALL_FUEL_TYPE_URL = "/api/FuelType/fetchfuelTypes";
export const GET_ALL_MANUFACTURERS_URL = "/api/Manufacturer/fetchManufacturers";
export const GET_ALL_FLOORPLAN_COMPANY_URL =
  "/api/FloorPlanCompany/fetchfloorplancompany";
export const GET_ALL_SELLING_PRICE_CATEGORY_URL =
  "/api/SellingPriceCategory/fetchsellingPriceCategory";
export const GET_ALL_WARRANTY_STATUS_URL =
  "/api/WarrantyStatus/fetchwarrantyStatus";
export const GET_ALL_TRANSMISSION_TYPE_URL =
  "/api/TransmissionType/fetchtransmissionType";
export const GET_ALL_ACCESSORIES_TYPE_URL = "/api/Accessories/fetchaccessories";
export const GET_ALL_YEARS_BY_TITLE_STATUS_URL =
  "/api/TitleStatus/fetchYearsByTitleStatusId";
export const GET_ALL_YEARS_URL = "/api/TitleStatus/fetchyears";
export const GET_ALL_ASSET_TYPES_BY_YEAR_URL =
  "/api/AssetType/fetchAssetTypesByUnitYearId";
export const GET_ALL_MANUFACTURERS_BY_ASSET_TYPE_URL =
  "/api/Manufacturer/fetchManufacturerByAssetTypeId";
export const GET_ALL_DIVISIONS_BY_MANUFACTURER_URL =
  "/api/Division/fetchDivisionByManufacturerId";
export const GET_ALL_BRANDS_BY_DIVISION_URL =
  "/api/UnitBrand/fetchUnitBrandByDivisionId";
export const GET_ALL_BRANDS_BY_MANUFACTURER_URL =
  "/api/UnitBrand/fetchUnitBrandByManufacturerId";
export const GET_ALL_SERIES_BY_BRAND_URL =
  "/api/UnitSeries/fetchUnitSeriesByUnitBrandId";
export const GET_ALL_MODELS_BY_BRAND_URL =
  "/api/UnitModel/fetchUnitModelsByUnitBrandId";
export const GET_ALL_MODELS_BY_SERIES_URL =
  "/api/UnitModel/fetchUnitModelsByUnitSeriesId";
export const GET_ALL_SUB_SERIES_BY_SERIES_URL =
  "/api/UnitSeries/fetchUnitSubSeriesBySeriesId";
export const GET_ALL_MODELS_BY_SUB_SERIES_URL =
  "/api/UnitModel/fetchUnitModelsBySubSeriesId";
export const GET_ALL_UNIT_STATUS_URL = "/api/UnitStatus/fetchUnitStatuses";
export const GET_ALL_UNIT_TYPES_URL = "/api/UnitType/fetchUnitTypes";
export const GET_ALL_BODY_STYLES_URL = "/api/BodyStyle/fetchbodystyles";
export const INVENTORY_BULK_IMPORT_URL = "/api/Asset/createbulkasset";
export const GET_ALL_PRODUCT_GENERAL_MANAGERS_URL =
  "/api/ProductGeneralManager/fetchproductGeneralManagers";
export const GET_ALL_DIVISIONS_URL = "/api/Division/fetchDivisions";
export const GET_ALL_FLOOR_PLAN_STATUSES_URL =
  "/api/FloorPlanStatus/fetchfloorPlanStatus";
export const GET_ALL_DIVISION_ALLOCATIONS_URL =
  "/api/DivisionAllocation/fetchdivisionAllocation";
export const GET_ALL_PROFIT_CENTERS_URL = "/api/Dealership/fetchprofitcenter";
export const GET_ALL_TRANSPORTERS_URL = "/api/Dispatch/fetchtransporter";
export const GET_ALL_LOTS_FOR_DEALERSHIP_URL =
  "/api/Dealership/fetchdealershiplot";
export const GET_ALL_PURCHASE_ORDER_STATUSES_URL =
  "/api/PurchaseStatus/fetchPurchaseStatus";
// Todo: Update this api url after deployed on staging.
export const GET_ALL_LOT_LOCATIONS_BY_LOT_URL =
  "/api/Dealership/fetchdealershiplotlocation";

// SubFloor Plan URLs
export const GET_ALL_SUB_FLOOR_PLANS_URL =
  "/api/SubFloorPlan/fetchSubFloorPlans";

// Floor Layout URLs
export const GET_ALL_FLOOR_LAYOUTS_URL =
  "/api/FloorLayout/fetchAllFloorLayouts";

// Asset Type URLs
export const GET_ALL_ASSET_TYPES_URL = "/api/AssetType/fetchAssetTypes";
//#endregion

//Time Clock API
export const GET_ALL_CHECK_IN_OUT_URL = "/api/Clocking/allcheckinout";
export const GET_ALL_CHECK_IN_OUT_BY_USERID_URL =
  "/api/Clocking/allcheckinoutbyuserid";
export const GET_CHECK_IN_OUT_BY_ID_URL =
  "/api/Clocking/checkinoutbyattendanceid";
export const CREATE_CHECKIN_URL = "/api/Clocking/checkin";
export const EDIT_DND_URL = "/api/Clocking/togglednd";
export const EDIT_CHECKOUT_URL = "/api/Clocking/checkout";
export const EDIT_TIMESHEET_URL = "/api/Clocking/edittimesheet";
export const GET_IP_ADDRESS = "https://api.ipify.org/?format=json";

//Dispatch API
export const GET_ALL_DISPATCH_URL = "/api/Dispatch/fetchdispatch";
export const CREATE_DISPATCH_URL = "/api/Dispatch/createdispatch";
export const EDIT_DISPATCH_URL = "/api/Dispatch/updatedispatch";
export const GET_DISPATCH_URL = "/api/Dispatch/fetchdispatchbyid";
export const ADD_DISPATCH_NOTE_URL = "/api/Dispatch/adddispatchnotes";
export const UPDATE_DISPATCH_STATUS_URL = "/api/Dispatch/updateddispatchstatus";
export const GET_DISPATCH_BY_MONTH_URL = "/api/Dispatch/fetchdispatchbymonth";

// Purchase Order API
export const GET_PURCHASE_ORDERS_URL = "/api/PurchaseOrder/fetchpurchaseorders";
export const GET_PURCHASE_ORDER_BY_ID_URL = "/api/PurchaseOrder/fetchbyid";
export const GET_PURCHASE_ORDER_HISTORY_BY_ID_URL =
  "/api/PurchaseOrder/getpurchaseorderhistory";

//Dealership
export const CREATE_DEALERSHIP_URL = "/api/Dealership/createdealership";
export const UPDATE_DEALERSHIP_URL = "/api/Dealership/updatedealership";
export const GET_ALL_DEALERSHIP_URL = "/api/Dealership/fetchdealership";
export const GET_BY_ID_DEALERSHIP_URL = "/api/Dealership/fetchdealershipbyid";
export const DELETE_DEALERSHIP_URL = "/api/Dealership/deletedealership";
export const CREATE_PURCHASE_ORDER_URL =
  "/api/PurchaseOrder/createpurchaseorder";
export const EDIT_PURCHASE_ORDER_URL = "/api/PurchaseOrder/updatepurchaseorder";
export const GET_TENANT_LOCATIONS = "/api/Account/tenant/location";
export const SWITCH_DEALERSHIP_URL = "/api/Dealership/switch";

//Dealership Settings
export const GET_ADDRESS_TYPE_URL = "/api/AddressType";
export const GET_PHONE_TYPE_URL = "/api/PhoneType";
export const GET_EMAIL_TYPE_URL = "/api/EmailType";
export const GET_WEB_TYPE_URL = "/api/WebType";
export const GET_API_TYPE_URL = "/api/ApiType";

// Accounting Transactions
export const GET_GLACCOUNTS_URL = "/api/GLAccount/fetchGLAccounts";
export const CREATE_TRANSACTION_URL = "/api/Transactions/createtransactions";
export const GET_ALL_TRANSACTIONS_URL = "/api/Transactions/fetchtransactions";
export const DOWNLOAD_ALL_TRANSACTIONS_URL =
  "/api/Transactions/downloadtransactions";
export const GET_TRANSACTIONS_BY_REFERENCE_NO_URL =
  "/api/Transactions/fetchtransactionbyreferencenumber";
export const UPDATE_TRANSACTION_POSTING_URL =
  "/api/Transactions/updatetransactionsposting";

// Finance
export const QUOTE_URL = "/api/Finance/quote";
export const GET_QUOTES_URL = "/api/Finance/quotes";
export const GET_DEALS_URL = "/api/Finance/deals";
export const VOID_QUOTE_URL = "/api/Finance/quote/void";
export const VOID_DEAL_URL = "/api/Finance/deal/void";
export const GET_QUOTE_STATUSES_URL = "/api/FinanceStatus";
export const GET_DEAL_TYPES_URL = "/api/DealType";
export const GET_PAYMENT_TYPES_URL = "/api/PaymentType";
export const DEAL_URL = "/api/Finance/deal";
export const TAKE_DEPOSIT_URL = "/api/Finance/deal/take-deposit";
export const CONVERT_TO_DEAL_URL = "/api/Finance/deal/quote-to-deal";
export const GET_FEES_URL = "/api/FinanceFeeType";
export const GET_TAXES_URL = "/api/FinanceTaxType";
export const GET_CREDIT_TYPES_URL = "/api/FinanceCreditType";
export const GET_QUOTE_HISTORY_URL = "/api/Finance/quote/history";
export const GET_QUOTE_SHEET_URL = "/api/FinanceFormGeneration/generate";

// Customer
export const GET_BUYERS = "/api/Customer/fetchallcustomers";
export const CREATE_BUYER = "/api/Customer";
export const GET_COBUYERS = "/api/Customer/cobuyers"; // INFO: ASSOCIATION_ID means by an other buyer
export const ASSOCIATE_COBUYERS = "/api/Customer/associatecobuyerswithbuyer"; // INFO: ASSOCIATION_ID means by an other buyer
export const TAGS_URL = "/api/CompanyTags";

// Interest
export const INTEREST_URL = "/api/Interest";
export const CUSTOMER_INTERESTS_URL = "/api/Interest/customer";

// Grid Settings
export const GRID_SETTINGS_URL = "/api/GridViewState";
