export const GRID_SETTINGS_FIELDS = {
  SETTINGS_NAME: "viewName",
  GRID_TYPE: "gridType",
  FILTER_SETTINGS: "filterSettings",
  SORT_SETTINGS: "sortSettings",
  COLUMN_SETTINGS: "columnSettings",
  IS_GLOBAL: "isGlobal",
  IS_INITIAL: "isInitial",
  IS_DEFAULT: "isDefault",
};
