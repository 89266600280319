import { gridSettingsSuccessMessages } from "appConstants";
import { GridSettingsState } from "./gridSettings.model";

export const saveGridSettingsFulfilled = (state: GridSettingsState, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = gridSettingsSuccessMessages.gridSettingsSaved;
};

export const saveGridSettingsRejected = (state: GridSettingsState, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const updateGridSettingsFulfilled = (
  state: GridSettingsState,
  action
) => {
  state.isLoading = false;
  state.isSuccess = true;
};

export const updateGridSettingsRejected = (
  state: GridSettingsState,
  action
) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const getAllGridSettingsFulfilled = (state, action) => {
  state.isLoading = false;
  state.settings = action.payload;
  if (action.payload?.length) {
    const findDefaultSetting = action.payload.find(
      (setting) => setting.isDefault && !setting.isInitial
    );

    state.currentSetting[action?.meta.arg?.gridType] =
      findDefaultSetting || null;

    const findInitialSetting = action.payload.find(
      (setting) => setting.isInitial
    );

    state.initialSetting[action?.meta.arg?.gridType] =
      findInitialSetting || null;
  }
};

export const getAllGridSettingsRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const getGridSettingsByIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.settingById[action.payload.gridType] = action.payload;
};

export const getGridSettingsByIdRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};
