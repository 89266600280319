export const templatesFulfilled = (state, action) => {
  state.isLoading = false;
  state.assetTemplates = action.payload;
};

export const templatesRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const templateByIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.assetTemplate = action.payload;
};

export const createTemplateFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = action.payload;
};

export const editTemplateFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = action.payload;
};

export const assetTemplateHistoryByIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.assetTemplateHistory = action.payload;
};
