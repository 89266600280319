import { createSlice } from "@reduxjs/toolkit";
import { GridSettingsState } from "./gridSettings.model";
import {
  getAllGridSettings,
  getGridSettingsById,
  saveGridSetting,
  updateGridSetting,
} from "./gridSettings.action";
import {
  getAllGridSettingsFulfilled,
  getAllGridSettingsRejected,
  getGridSettingsByIdFulfilled,
  getGridSettingsByIdRejected,
  saveGridSettingsFulfilled,
  saveGridSettingsRejected,
  updateGridSettingsFulfilled,
  updateGridSettingsRejected,
} from "./gridSettings.reducer";
import { setReduxCommonPendingState } from "utils/common";

const initialState: GridSettingsState = {
  isLoading: false,
  successMessage: "",
  error: { title: "", description: "" },
  isSuccess: false,
  initialSetting: {},
  currentSetting: {},
  settingById: {},
  settings: [],
};

export const gridSettingsSlice = createSlice({
  name: "gridSettings",
  initialState: initialState,
  reducers: {
    setGridCurrentSetting: (state, action) => {
      state.currentSetting = action.payload;
    },
    resetSuccess: (state) => {
      state.successMessage = "";
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => (
    builder.addCase(saveGridSetting.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(saveGridSetting.fulfilled, (state, action) => {
      saveGridSettingsFulfilled(state, action);
    }),
    builder.addCase(saveGridSetting.rejected, (state, action) => {
      saveGridSettingsRejected(state, action);
    }),
    builder.addCase(updateGridSetting.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(updateGridSetting.fulfilled, (state, action) => {
      updateGridSettingsFulfilled(state, action);
    }),
    builder.addCase(updateGridSetting.rejected, (state, action) => {
      updateGridSettingsRejected(state, action);
    }),
    builder.addCase(getAllGridSettings.pending, (state) => {
      setReduxCommonPendingState(state);
      state.settings = [];
    }),
    builder.addCase(getAllGridSettings.fulfilled, (state, action) => {
      getAllGridSettingsFulfilled(state, action);
    }),
    builder.addCase(getAllGridSettings.rejected, (state, action) => {
      getAllGridSettingsRejected(state, action);
    }),
    builder.addCase(getGridSettingsById.pending, (state) => {
      setReduxCommonPendingState(state);
      state.settings = [];
    }),
    builder.addCase(getGridSettingsById.fulfilled, (state, action) => {
      getGridSettingsByIdFulfilled(state, action);
    }),
    builder.addCase(getGridSettingsById.rejected, (state, action) => {
      getGridSettingsByIdRejected(state, action);
    })
  ),
});

export const { setGridCurrentSetting, resetSuccess, resetError, reset } =
  gridSettingsSlice.actions;
