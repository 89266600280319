import { dealershipSuccessMessages } from "appConstants";

export const addressTypesFulfilled = (state, action) => {
  state.isLoading = false;
  state.addressTypes = action.payload;
};

export const addressTypesRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};
export const phoneTypesFulfilled = (state, action) => {
  state.isLoading = false;
  state.phoneTypes = action.payload;
};

export const phoneTypesRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};
export const emailTypesFulfilled = (state, action) => {
  state.isLoading = false;
  state.emailTypes = action.payload;
};

export const emailTypesRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};
export const webTypesFulfilled = (state, action) => {
  state.isLoading = false;
  state.webTypes = action.payload;
};

export const webTypesRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const apiTypesFulfilled = (state, action) => {
  state.isLoading = false;
  state.apiTypes = action.payload;
};

export const apiTypesRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const allDealershipFulfilled = (state, action) => {
  state.isLoading = false;
  state.allDealerships = action.payload;
};

export const allDealershipRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const allLocationsFulfilled = (state, action) => {
  state.isLoading = false;
  state.allLocations = action.payload;
};

export const allLocationsRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const createDealershipFulfilled = (state, action) => {
  state.isLoading = false;
  state.successMessage = action.payload;
};

export const createDealershipRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const updateDealershipFulfilled = (state, action) => {
  state.isLoading = false;
  state.successMessage = dealershipSuccessMessages.updateDealership;
};

export const updateDealershipRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const getDealershipFulfilled = (state, action) => {
  state.isLoading = false;
  state.dealership = action.payload;
};

export const getDealershipRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const switchDealershipFulfilled = (state, action) => {
  state.isLoading = false;
  // state.dealership = action.payload;
};

export const switchDealershipRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};
