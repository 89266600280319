import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_ALL_TEMPLATES_URL,
  GET_ASSET_TEMPLATE_HISTORY_BY_ID_URL,
  TEMPLATE_URL,
} from "utils/urls";
import { getRequest, postRequest, putRequest } from "utils/axiosClient";
import {
  CreateAssetPayload,
  GetFilteredOrSearchedAssetsPayload,
  GetViewAssetTemplatePayload,
} from "types/inventory";
import {
  API_RESPONSE_TYPE,
  ERROR_MESSAGES,
  MULTIPART_HEADER,
  STATUS_CODE,
  inventorySuccessMessages,
} from "appConstants";
import AppError from "utils/AppError";
import { generateQueryParams } from "utils/common";
import { convertDimensions } from "containers/inventory/asset/createasset/conversion";
import { Asset, PaginatedResponse } from "types";

const actionTypes = {
  GET_ALL: "getAllTemplates",
  GET: "getTemplate",
  CREATE: "createTemplate",
  EDIT: "editTemplate",
};

export const getAllAssetTemplates = createAsyncThunk<
  PaginatedResponse<Asset>,
  GetFilteredOrSearchedAssetsPayload
>(actionTypes.GET_ALL, async (payload: GetFilteredOrSearchedAssetsPayload) => {
  try {
    const queryParams = generateQueryParams({
      page: payload.page,
      search: payload.search,
      field: payload.field,
      sort: payload.sort,
      filter: payload.filter,
      dispatchFilter: payload.dispatchFilter,
      StockNumber: payload.StockNumber,
      customerId: payload.customerId,
      ...(payload.fetchUnAssign === false ? {} : { fetchUnAssign: true }),
      ...(payload.isRoleBased ? { isRoleBased: true } : {}),
    });

    // TODO: any type will be replace with appropriate type
    const response = await getRequest<any>(
      `${GET_ALL_TEMPLATES_URL}?${queryParams}`
    );

    return response.data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getAssetTemplates,
      error.response.data?.message
    );
  }
});

export const createAssetTemplate = createAsyncThunk(
  actionTypes.CREATE,
  async (payload: CreateAssetPayload) => {
    try {
      // TODO: any type will be replace with appropriate type
      const response = await postRequest<any>(
        TEMPLATE_URL,
        payload.formData,
        MULTIPART_HEADER
      );
      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        return inventorySuccessMessages.createTemplate;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createTemplate,
        error.response.data?.message
      );
    }
  }
);

export const editAssetTemplate = createAsyncThunk(
  actionTypes.EDIT,
  async (payload: CreateAssetPayload) => {
    try {
      const response = await putRequest(
        TEMPLATE_URL,
        payload.formData,
        MULTIPART_HEADER
      );
      if (response.status === STATUS_CODE.UPDATE_SUCCESS) {
        return inventorySuccessMessages.editTemplate;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.editTemplate,
        error.response.data?.message
      );
    }
  }
);

export const getAssetTemplateById = createAsyncThunk(
  actionTypes.GET,
  async (payload: GetViewAssetTemplatePayload) => {
    try {
      const response = await getRequest<any>(`${TEMPLATE_URL}/${payload.id}`, {
        metadata: { expectedType: API_RESPONSE_TYPE.OBJECT },
      });
      const { data } = response;
      const manipulatedData = convertDimensions(data);

      return manipulatedData;
    } catch (error) {
      if (error.response) {
        throw new AppError(
          ERROR_MESSAGES.getAssetTemplateById,
          error.response.data?.message
        );
      }
    }
  }
);

export const getAssetTemplateHistoryById = createAsyncThunk(
  GET_ASSET_TEMPLATE_HISTORY_BY_ID_URL,
  async (payload: GetViewAssetTemplatePayload) => {
    try {
      const response = await getRequest(GET_ASSET_TEMPLATE_HISTORY_BY_ID_URL, {
        params: payload,
      });
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAssetTemplateHistoryById,
        error.response.data?.message
      );
    }
  }
);
