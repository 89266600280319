import React, { MutableRefObject } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { cancelBtnStyle, gutterSpace, pxToRem } from "appConstants";
import { Button, CheckboxWrapper, PerformantTextField } from "components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useSaveGridSettingsDialog from "./useSaveGridSettingsDialog";
import { GRID_SETTINGS_FIELDS } from "appConstants/FormFields";

type SaveGridSettingsDialogPropType = {
  onClose: () => void;
  gridApiRef: MutableRefObject<GridApiPremium | null>;
};

const { SETTINGS_NAME, IS_DEFAULT } = GRID_SETTINGS_FIELDS;

const SaveGridSettingsDialog: React.FC<SaveGridSettingsDialogPropType> = ({
  onClose,
  gridApiRef,
}) => {
  const {
    configurationNameLabel,
    saveAsDefaultLabel,
    cancel,
    save,
    settingsNameValue,
    saveAsDefaultValue,
    isValid,
    handleChange,
    handleSubmit,
  } = useSaveGridSettingsDialog();

  return (
    <Box>
      <DialogTitle>
        <Box sx={{ flex: 1 }}>
          <ContentCopyIcon sx={{ mr: pxToRem(14.8), mb: pxToRem(-3) }} />
          Please Confirm
          <Typography variant="body1" lineHeight={pxToRem(24)} fontWeight={500}>
            You are Saving a Data Grid Configuration
          </Typography>
          <Typography variant="body2" mt={pxToRem(12)} pb={pxToRem(9)}>
            Please name this configuration to access it again.
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ maxHeight: pxToRem(202.5) }}>
        <Grid container spacing={gutterSpace} component="form">
          <Grid item xs={7}>
            <PerformantTextField
              fullWidth={true}
              label={configurationNameLabel}
              type="text"
              name={SETTINGS_NAME}
              value={settingsNameValue}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={5}>
            <CheckboxWrapper
              label={saveAsDefaultLabel}
              name={IS_DEFAULT}
              onChange={handleChange}
              checked={saveAsDefaultValue as boolean}
              sx={{ ml: pxToRem(11) }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ pt: "1.5rem !important" }}>
        <Grid container spacing={gutterSpace}>
          <Grid item xs={6}>
            <Button onClick={onClose} combinedSx={cancelBtnStyle}>
              {cancel}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button disabled={!isValid} onClick={() => handleSubmit()}>
              {save}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Box>
  );
};

export default SaveGridSettingsDialog;
