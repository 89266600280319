import { createSlice } from "@reduxjs/toolkit";
import { AssetState } from "./inventory.model";
import {
  createAsset,
  getAllAssets,
  getAsset,
  getAllUnitInfoAndPricingOptions,
  getAllAssetTypesByYear,
  getAllBrandsByDivisionId,
  getAllDivisionsByManufacturerId,
  getAllManufacturersByAssetTypeId,
  getAllModelsBySeriesId,
  getAllSeriesByBrandId,
  getAllTitleStatuses,
  getAllYearsByTitleStatus,
  getAllModelsBySubseriesId,
  getAllSubseriesBySeriesId,
  getAllModelsByBrandId,
  getAllUnitStatuses,
  getAllUnitTypes,
  getAllBodyStyles,
  getAllCylinderType,
  getAllTransmissionType,
  getAllFuelType,
  getAllAccessoriesType,
  getAllWarrantyStatus,
  uploadAssetBulkImportFile,
  getAllProductGeneralManagers,
  getAllDivisions,
  getAllFloorPlanStatuses,
  getAllDivisionAllocations,
  getAllProfitCenters,
  getAllSellingPriceCategory,
  getAllFloorPlanCompany,
  getAllManufacturer,
  getAllLotsByDealershipId,
  getAllLotLocationsByLotId,
  createAccountingTransaction,
  editAsset,
  getAllTransporters,
  getAllUnlinkedItems,
  getAllTransactions,
  getTransactionsByReferenceNo,
  updateTransactionPosting,
  getAssetHistoryDetailsByAssetId,
  getAssetHistoryByAssetId,
  getAssetByStockNumber,
  createDuplicateAsset,
  getAllGLAccounts,
  getSuggestedAssets,
} from "./inventory.action";
import {
  assetsFulfilled,
  assetsRejected,
  createAssetFulfilled,
  AssetRejected,
  infoAndPricingOptionsFulfilled,
  viewAllAssetTypesByYearFulfilled,
  viewAllBodyStylesFulfilled,
  viewAllBrandsByDivisionIdFulfilled,
  viewAllDivisionsByManufacturerIdFulfilled,
  viewAllManufacturersByAssetTypeIdFulfilled,
  viewAllModelsByBrandIdFulfilled,
  viewAllModelsBySeriesIdFulfilled,
  viewAllModelsBySubseriesIdFulfilled,
  viewAllSeriesByBrandIdFulfilled,
  viewAllSubseriesBySeriesIdFulfilled,
  viewAllUnitStatusesFulfilled,
  viewAllUnitTypesFulfilled,
  viewAllYearsByTitleStatusFulfilled,
  uploadAssetBulkImportFileFulfilled,
  uploadAssetBulkImportFileRejected,
  viewAssetFulfilled,
  viewAssetRejected,
  viewTitleStatusesFulfilled,
  viewCylindersFulfilled,
  viewTransmissionsFulfilled,
  viewFuelFulfilled,
  viewAccessoriesFulfilled,
  viewAllWarrantyStatusFulfilled,
  viewAllProductGeneralManagersFulfilled,
  viewAllDivisionsFulfilled,
  viewAllFloorPlanStatusesFulfilled,
  viewAllDivisionAllocationsFulfilled,
  viewAllProfitCentersFulfilled,
  viewSellingPriceCategoryFulfilled,
  viewFloorPlanCompanyFulfilled,
  viewAllManufacturersFulfilled,
  viewAllLotsByDealershipIdFulfilled,
  viewAllLotLocationByLotIdFulfilled,
  creatTransactionFulfilled,
  editAssetFulfilled,
  viewAllTransportersFulfilled,
  viewAllUnlinkedItemsFulfilled,
  getAllTransactionsFulfilled,
  getAllTransactionsRejected,
  getTransactionsByRefFulfilled,
  getAllTransactionsByRefRejected,
  updateTransactionPostingFulfilled,
  updateTransactionPostingRejected,
  getAssetHistoryDetailsFulfilled,
  getAssetHistoryDetailsRejected,
  getAssetHistoryFulfilled,
  getAssetHistoryRejected,
  assetByStockNumberFulfilled,
  assetByStockNumberRejected,
  createDuplicateAssetFulfilled,
  getGLAccountsFulfilled,
  getGLAccountsRejected,
  suggestedAssetsFulfilled,
  suggestedAssetsRejected,
} from "./inventory.reducer";

const assetByStockNumber = { asset: null, dispatchDetails: null };

const initialState: AssetState = {
  isLoading: false,
  successMessage: "",
  error: { title: "", description: "" },
  assets: [],
  suggestedAssets: [],
  selectedAssets: [],
  selectedDealershipLots: [],
  selectedLotLocations: [],
  asset: null,
  isSuccess: false,
  createEditIsSuccess: false,
  editSuccessMessage: "",
  isImportSuccess: false,
  assetTypes: [],
  floorLayouts: [],
  subFloorPlans: [],
  isAssetListLoading: false,
  cylinders: [],
  transmissions: [],
  warrantyStatus: [],
  fuels: [],
  accessories: [],
  titleStatuses: [],
  unitYears: [],
  manufacturers: [],
  divisions: [],
  unitBrands: [],
  series: [],
  unitModels: [],
  subSeries: [],
  unitStatuses: [],
  unitTypes: [],
  bodyStyles: [],
  productGeneralManagers: [],
  allDivisions: [],
  floorPlanStatuses: [],
  divisionAllocations: [],
  profitCenters: undefined,
  sellingPriceCategory: [],
  floorPlanCompany: [],
  transporters: [],
  dealershipLots: [],
  lotLocations: [],
  transactions: [],
  transactionsByRef: [],
  assetHistoryDetails: [],
  assetHistory: undefined,
  assetByStockNumber: assetByStockNumber,
  totalRecords: null,
  GLAccounts: null,
};

export const assetSlice = createSlice({
  name: "asset",
  initialState: initialState,
  reducers: {
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    resetSuccess: (state) => {
      state.isSuccess = false;
      state.isImportSuccess = false;
      state.successMessage = "";
    },
    reset: () => initialState,
    setSelectedAssets: (state, action) => {
      state.selectedAssets = action.payload;
    },
    setSelectedDealershipLots: (state, action) => {
      state.selectedDealershipLots = action.payload;
    },
    setSelectedLotLocations: (state, action) => {
      state.selectedLotLocations = action.payload;
    },
    resetUnitYears: (state) => {
      state.unitYears = [];
    },
    resetAssetTypes: (state) => {
      state.assetTypes = [];
    },
    resetManufacturers: (state) => {
      state.manufacturers = [];
    },
    resetDivisions: (state) => {
      state.divisions = [];
    },
    resetUnitBrands: (state) => {
      state.unitBrands = [];
    },
    resetSeries: (state) => {
      state.series = [];
    },
    resetSubseries: (state) => {
      state.subSeries = [];
    },
    resetUnitModels: (state) => {
      state.unitModels = [];
    },
    resetDealershipLots: (state) => {
      state.dealershipLots = [];
    },
    resetLotLocations: (state) => {
      state.lotLocations = [];
    },
    resetAsset: (state) => {
      state.asset = null;
    },
    resetAllAssets: (state) => {
      state.assets = [];
    },
    resetAssetByStockNumber: (state) => {
      state.assetByStockNumber = assetByStockNumber;
    },
    resetSuggestedAssets: (state) => {
      state.suggestedAssets = [];
    },
    resetSupportingData: (state) => {
      state.divisions = [];
      state.unitBrands = [];
      state.series = [];
      state.unitModels = [];
      state.subSeries = [];
      state.allDivisions = [];
      state.divisionAllocations = [];
      state.lotLocations = [];
      state.dealershipLots = [];
    },
  },
  extraReducers: (builder) => (
    builder.addCase(getAllAssets.pending, (state, action) => {
      state.isLoading = true;
      state.assets = [];
      state.asset = null;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.isAssetListLoading = true;
      state.successMessage = "";
    }),
    builder.addCase(getAllAssets.fulfilled, (state, action) => {
      assetsFulfilled(state, action);
    }),
    builder.addCase(getAllAssets.rejected, (state, action) => {
      assetsRejected(state, action);
    }),
    builder.addCase(getAsset.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAsset.fulfilled, (state, action) => {
      viewAssetFulfilled(state, action);
    }),
    builder.addCase(getAsset.rejected, (state, action) => {
      viewAssetRejected(state, action);
    }),
    builder.addCase(uploadAssetBulkImportFile.pending, (state, action) => {
      state.isLoading = true;
      state.isImportSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(uploadAssetBulkImportFile.fulfilled, (state, action) => {
      uploadAssetBulkImportFileFulfilled(state, action);
    }),
    builder.addCase(uploadAssetBulkImportFile.rejected, (state, action) => {
      uploadAssetBulkImportFileRejected(state, action);
    }),
    builder.addCase(createAsset.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(createAsset.fulfilled, (state, action) => {
      createAssetFulfilled(state, action);
    }),
    builder.addCase(createAsset.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllGLAccounts.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllGLAccounts.fulfilled, (state, action) => {
      getGLAccountsFulfilled(state, action);
    }),
    builder.addCase(getAllGLAccounts.rejected, (state, action) => {
      getGLAccountsRejected(state, action);
    }),
    builder.addCase(
      getAllUnitInfoAndPricingOptions.fulfilled,
      (state, action) => {
        infoAndPricingOptionsFulfilled(state, action);
      }
    ),
    builder.addCase(
      getAllUnitInfoAndPricingOptions.pending,
      (state, action) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error.title = "";
        state.error.description = "";
        state.successMessage = "";
      }
    ),
    builder.addCase(
      getAllUnitInfoAndPricingOptions.rejected,
      (state, action) => {
        AssetRejected(state, action);
      }
    ),
    builder.addCase(getAllTitleStatuses.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllTitleStatuses.fulfilled, (state, action) => {
      viewTitleStatusesFulfilled(state, action);
    }),
    builder.addCase(getAllTitleStatuses.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllCylinderType.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllCylinderType.fulfilled, (state, action) => {
      viewCylindersFulfilled(state, action);
    }),
    builder.addCase(getAllCylinderType.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllTransmissionType.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllTransmissionType.fulfilled, (state, action) => {
      viewTransmissionsFulfilled(state, action);
    }),
    builder.addCase(getAllTransmissionType.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllFuelType.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllFuelType.fulfilled, (state, action) => {
      viewFuelFulfilled(state, action);
    }),
    builder.addCase(getAllFuelType.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllAccessoriesType.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllAccessoriesType.fulfilled, (state, action) => {
      viewAccessoriesFulfilled(state, action);
    }),
    builder.addCase(getAllAccessoriesType.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllWarrantyStatus.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllWarrantyStatus.fulfilled, (state, action) => {
      viewAllWarrantyStatusFulfilled(state, action);
    }),
    builder.addCase(getAllWarrantyStatus.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllSellingPriceCategory.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAllSellingPriceCategory.fulfilled, (state, action) => {
      viewSellingPriceCategoryFulfilled(state, action);
    }),
    builder.addCase(getAllSellingPriceCategory.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllFloorPlanCompany.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAllFloorPlanCompany.fulfilled, (state, action) => {
      viewFloorPlanCompanyFulfilled(state, action);
    }),
    builder.addCase(getAllFloorPlanCompany.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllManufacturer.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAllManufacturer.fulfilled, (state, action) => {
      viewAllManufacturersFulfilled(state, action);
    }),
    builder.addCase(getAllManufacturer.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllYearsByTitleStatus.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllYearsByTitleStatus.fulfilled, (state, action) => {
      viewAllYearsByTitleStatusFulfilled(state, action);
    }),
    builder.addCase(getAllYearsByTitleStatus.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllAssetTypesByYear.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllAssetTypesByYear.fulfilled, (state, action) => {
      viewAllAssetTypesByYearFulfilled(state, action);
    }),
    builder.addCase(getAllAssetTypesByYear.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(
      getAllManufacturersByAssetTypeId.pending,
      (state, action) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error.title = "";
        state.error.description = "";
        state.successMessage = "";
      }
    ),
    builder.addCase(
      getAllManufacturersByAssetTypeId.fulfilled,
      (state, action) => {
        viewAllManufacturersByAssetTypeIdFulfilled(state, action);
      }
    ),
    builder.addCase(
      getAllManufacturersByAssetTypeId.rejected,
      (state, action) => {
        AssetRejected(state, action);
      }
    ),
    builder.addCase(
      getAllDivisionsByManufacturerId.pending,
      (state, action) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error.title = "";
        state.error.description = "";
        state.successMessage = "";
      }
    ),
    builder.addCase(
      getAllDivisionsByManufacturerId.fulfilled,
      (state, action) => {
        viewAllDivisionsByManufacturerIdFulfilled(state, action);
      }
    ),
    builder.addCase(
      getAllDivisionsByManufacturerId.rejected,
      (state, action) => {
        AssetRejected(state, action);
      }
    ),
    builder.addCase(getAllBrandsByDivisionId.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllBrandsByDivisionId.fulfilled, (state, action) => {
      viewAllBrandsByDivisionIdFulfilled(state, action);
    }),
    builder.addCase(getAllBrandsByDivisionId.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllSeriesByBrandId.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllSeriesByBrandId.fulfilled, (state, action) => {
      viewAllSeriesByBrandIdFulfilled(state, action);
    }),
    builder.addCase(getAllSeriesByBrandId.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllModelsBySeriesId.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllModelsBySeriesId.fulfilled, (state, action) => {
      viewAllModelsBySeriesIdFulfilled(state, action);
    }),
    builder.addCase(getAllModelsBySeriesId.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllSubseriesBySeriesId.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.successMessage = "";
      state.error.description = "";
    }),
    builder.addCase(getAllSubseriesBySeriesId.fulfilled, (state, action) => {
      viewAllSubseriesBySeriesIdFulfilled(state, action);
    }),
    builder.addCase(getAllSubseriesBySeriesId.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllModelsBySubseriesId.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllModelsBySubseriesId.fulfilled, (state, action) => {
      viewAllModelsBySubseriesIdFulfilled(state, action);
    }),
    builder.addCase(getAllModelsBySubseriesId.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllModelsByBrandId.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllModelsByBrandId.fulfilled, (state, action) => {
      viewAllModelsByBrandIdFulfilled(state, action);
    }),
    builder.addCase(getAllModelsByBrandId.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllUnitStatuses.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllUnitStatuses.fulfilled, (state, action) => {
      viewAllUnitStatusesFulfilled(state, action);
    }),
    builder.addCase(getAllUnitStatuses.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllUnitTypes.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllUnitTypes.fulfilled, (state, action) => {
      viewAllUnitTypesFulfilled(state, action);
    }),
    builder.addCase(getAllUnitTypes.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllBodyStyles.pending, (state, action) => {
      state.isLoading = true;
      state.createEditIsSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllBodyStyles.fulfilled, (state, action) => {
      viewAllBodyStylesFulfilled(state, action);
    }),
    builder.addCase(getAllBodyStyles.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllProductGeneralManagers.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllProductGeneralManagers.fulfilled, (state, action) => {
      viewAllProductGeneralManagersFulfilled(state, action);
    }),
    builder.addCase(getAllProductGeneralManagers.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllDivisions.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.successMessage = "";
      state.error.description = "";
    }),
    builder.addCase(getAllDivisions.fulfilled, (state, action) => {
      viewAllDivisionsFulfilled(state, action);
    }),
    builder.addCase(getAllDivisions.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllFloorPlanStatuses.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllFloorPlanStatuses.fulfilled, (state, action) => {
      viewAllFloorPlanStatusesFulfilled(state, action);
    }),
    builder.addCase(getAllFloorPlanStatuses.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllDivisionAllocations.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllDivisionAllocations.fulfilled, (state, action) => {
      viewAllDivisionAllocationsFulfilled(state, action);
    }),
    builder.addCase(getAllDivisionAllocations.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllProfitCenters.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllProfitCenters.fulfilled, (state, action) => {
      viewAllProfitCentersFulfilled(state, action);
    }),
    builder.addCase(getAllProfitCenters.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllLotsByDealershipId.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllLotsByDealershipId.fulfilled, (state, action) => {
      viewAllLotsByDealershipIdFulfilled(state, action);
    }),
    builder.addCase(getAllLotsByDealershipId.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllLotLocationsByLotId.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAllLotLocationsByLotId.fulfilled, (state, action) => {
      viewAllLotLocationByLotIdFulfilled(state, action);
    }),
    builder.addCase(getAllLotLocationsByLotId.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(createAccountingTransaction.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(editAsset.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.successMessage = "";
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(editAsset.fulfilled, (state, action) => {
      editAssetFulfilled(state, action);
    }),
    builder.addCase(editAsset.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllTransporters.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(createAccountingTransaction.fulfilled, (state, action) => {
      creatTransactionFulfilled(state, action);
    }),
    builder.addCase(createAccountingTransaction.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllTransactions.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAllTransactions.fulfilled, (state, action) => {
      getAllTransactionsFulfilled(state, action);
    }),
    builder.addCase(getAllTransactions.rejected, (state, action) => {
      getAllTransactionsRejected(state, action);
    }),
    builder.addCase(getTransactionsByReferenceNo.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAllTransporters.fulfilled, (state, action) => {
      viewAllTransportersFulfilled(state, action);
    }),
    builder.addCase(getAllTransporters.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getAllUnlinkedItems.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.successMessage = "";
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAllUnlinkedItems.fulfilled, (state, action) => {
      viewAllUnlinkedItemsFulfilled(state, action);
    }),
    builder.addCase(getAllUnlinkedItems.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(
      getAssetHistoryDetailsByAssetId.pending,
      (state, action) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.successMessage = "";
        state.error.title = "";
        state.error.description = "";
        state.assetHistoryDetails = [];
      }
    ),
    builder.addCase(
      getAssetHistoryDetailsByAssetId.fulfilled,
      (state, action) => {
        getAssetHistoryDetailsFulfilled(state, action);
      }
    ),
    builder.addCase(
      getAssetHistoryDetailsByAssetId.rejected,
      (state, action) => {
        getAssetHistoryDetailsRejected(state, action);
      }
    ),
    builder.addCase(getAssetHistoryByAssetId.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.successMessage = "";
      state.error.title = "";
      state.error.description = "";
      state.assetHistory = undefined;
    }),
    builder.addCase(getAssetHistoryByAssetId.fulfilled, (state, action) => {
      getAssetHistoryFulfilled(state, action);
    }),
    builder.addCase(getAssetHistoryByAssetId.rejected, (state, action) => {
      getAssetHistoryRejected(state, action);
    }),
    builder.addCase(getTransactionsByReferenceNo.fulfilled, (state, action) => {
      getTransactionsByRefFulfilled(state, action);
    }),
    builder.addCase(getTransactionsByReferenceNo.rejected, (state, action) => {
      getAllTransactionsByRefRejected(state, action);
    }),
    builder.addCase(updateTransactionPosting.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(updateTransactionPosting.fulfilled, (state, action) => {
      updateTransactionPostingFulfilled(state, action);
    }),
    builder.addCase(updateTransactionPosting.rejected, (state, action) => {
      updateTransactionPostingRejected(state, action);
    }),
    builder.addCase(getAssetByStockNumber.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.successMessage = "";
    }),
    builder.addCase(getAssetByStockNumber.fulfilled, (state, action) => {
      assetByStockNumberFulfilled(state, action);
    }),
    builder.addCase(getAssetByStockNumber.rejected, (state, action) => {
      assetByStockNumberRejected(state, action);
    }),
    builder.addCase(createDuplicateAsset.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(createDuplicateAsset.fulfilled, (state, action) => {
      createDuplicateAssetFulfilled(state, action);
    }),
    builder.addCase(createDuplicateAsset.rejected, (state, action) => {
      AssetRejected(state, action);
    }),
    builder.addCase(getSuggestedAssets.pending, (state, action) => {
      state.isLoading = true;
      state.suggestedAssets = [];
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getSuggestedAssets.fulfilled, (state, action) => {
      suggestedAssetsFulfilled(state, action);
    }),
    builder.addCase(getSuggestedAssets.rejected, (state, action) => {
      suggestedAssetsRejected(state, action);
    })
  ),
});

export const {
  resetError,
  resetSuccess,
  reset,
  setSelectedAssets,
  setSelectedDealershipLots,
  setSelectedLotLocations,
  resetAsset,
  resetAllAssets,
  resetAssetByStockNumber,
  resetAssetTypes,
  resetDivisions,
  resetManufacturers,
  resetSeries,
  resetSubseries,
  resetUnitBrands,
  resetUnitYears,
  resetUnitModels,
  resetDealershipLots,
  resetLotLocations,
  resetSuggestedAssets,
  resetSupportingData,
} = assetSlice.actions;
