import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_RESPONSE_TYPE,
  ERROR_MESSAGES,
  inventorySuccessMessages,
  MULTIPART_HEADER,
  STATUS_CODE,
} from "appConstants";
import { PurchasePayload } from "types/purchase";
import {
  GetByIdPayload,
  GetFilteredOrSearchedAssetsPayload,
  GetViewAssetTemplatePayload,
} from "types/inventory";
import { getRequest, postRequest, putRequest } from "utils/axiosClient";
import {
  CREATE_PURCHASE_ORDER_URL,
  GET_PURCHASE_ORDERS_URL,
  GET_PURCHASE_ORDER_BY_ID_URL,
  GET_PURCHASE_ORDER_HISTORY_BY_ID_URL,
  EDIT_PURCHASE_ORDER_URL,
  GET_ALL_PURCHASE_ORDER_STATUSES_URL,
} from "utils/urls";
import AppError from "utils/AppError";
import { generateQueryParams } from "utils/common";
import { convertDimensions } from "containers/inventory/asset/createasset/conversion";

export const getPurchaseOrders = createAsyncThunk(
  GET_PURCHASE_ORDERS_URL,
  async (payload: GetFilteredOrSearchedAssetsPayload) => {
    try {
      const queryParams = generateQueryParams({
        page: payload.page,
        search: payload.search,
        filter: payload.filter,
        field: payload.field,
        sort: payload.sort,
      });
      // TODO: any type will be replace with appropriate type
      const response = await getRequest<any>(
        `${GET_PURCHASE_ORDERS_URL}?${queryParams}`
      );
      return {
        records: response.data?.records,
        totalRecords: response.data.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getPurchaseOrder,
        error.response.data?.message
      );
    }
  }
);

export const createPurchaseOrder = createAsyncThunk(
  CREATE_PURCHASE_ORDER_URL,
  async (payload: PurchasePayload) => {
    try {
      const response = await postRequest(
        CREATE_PURCHASE_ORDER_URL,
        payload.formData,
        MULTIPART_HEADER
      );
      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        return inventorySuccessMessages.createPurchaseOrder;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createPurchaseOrder,
        error.response.data?.message
      );
    }
  }
);

export const editPurchaseOrder = createAsyncThunk(
  EDIT_PURCHASE_ORDER_URL,
  async (payload: PurchasePayload) => {
    try {
      const response = await putRequest(
        EDIT_PURCHASE_ORDER_URL,
        payload.formData,
        MULTIPART_HEADER
      );
      if (
        response.status === STATUS_CODE.UPDATE_SUCCESS ||
        response.status === STATUS_CODE.SUCCESS
      ) {
        return inventorySuccessMessages.editPurchaseOrder;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.editPurchaseOrder,
        error.response.data?.message
      );
    }
  }
);

export const getPurchaseOrderById = createAsyncThunk(
  GET_PURCHASE_ORDER_BY_ID_URL,
  async (payload: GetViewAssetTemplatePayload) => {
    try {
      // TODO: any type will be replace with appropriate type
      const response = await getRequest<any>(GET_PURCHASE_ORDER_BY_ID_URL, {
        params: payload,
        metadata: { expectedType: API_RESPONSE_TYPE.OBJECT },
      });
      const { data } = response;
      const manipulatedData = {
        ...data,
        assets: data.assets.map((asset) => convertDimensions(asset)),
      };
      return manipulatedData;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getPurchaseOrder,
        error.response.data?.message
      );
    }
  }
);

export const getPurchaseOrderHistoryById = createAsyncThunk(
  GET_PURCHASE_ORDER_HISTORY_BY_ID_URL,
  async (payload: GetByIdPayload) => {
    try {
      const response = await getRequest(GET_PURCHASE_ORDER_HISTORY_BY_ID_URL, {
        params: payload,
      });
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getPurchaseOrderHistory,
        error.response.data?.message
      );
    }
  }
);

export const getAllPurchaseOrderStatuses = createAsyncThunk(
  GET_ALL_PURCHASE_ORDER_STATUSES_URL,
  async () => {
    try {
      // TODO: any type will be replace with appropriate type
      const response = await getRequest<any>(
        GET_ALL_PURCHASE_ORDER_STATUSES_URL
      );
      const statuses = response.data?.filter((status) => status.isActive);
      return statuses;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllPurchaseOrderStatusError,
        error.response.data?.message
      );
    }
  }
);
