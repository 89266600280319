import { AlertColor } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { RoleState } from "redux/slices/role/role.model";
import { SUCCESS_MESSAGE_TIMEOUT } from "appConstants/index";

import {
  resetError as resetDealershipError,
  resetSuccess as resetDealershipSuccess,
} from "redux/slices/dealership/dealership.slice";

import {
  resetError as resetInventoryError,
  resetSuccess as resetInventorySuccess,
} from "redux/slices/inventory/inventory.slice";

import {
  resetError as resetAssetTemplateError,
  resetSuccess as resetAssetTemplateSuccess,
} from "redux/slices/assetTemplates/assetTemplates.slice";

import {
  resetError as resetDispatchError,
  resetSuccess as resetDispatchSuccess,
} from "redux/slices/dispatch/dispatch.slice";

import {
  resetError as resetPurchaseError,
  resetSuccess as resetPurchaseSuccess,
} from "redux/slices/purchasing/purchasing.slice";

import {
  resetError as resetFinanceError,
  resetSuccess as resetFinanceSuccess,
} from "redux/slices/finance/finance.slice";

import {
  resetError as resetCustomerError,
  resetSuccess as resetCustomerSuccess,
} from "redux/slices/customer/customer.slice";

import {
  resetError as resetPersonnelError,
  resetSuccess as resetPersonnelSuccess,
} from "redux/slices/personnel/personnel.slice";

import {
  resetError as resetRoleError,
  resetSuccess as resetRoleSuccess,
} from "redux/slices/role/role.slice";

import { resetError as resetTimeClockError } from "redux/slices/timeClock/timeClock.slice";

import {
  resetError as resetGridSettingsError,
  resetSuccess as resetGridSettingsSuccess,
} from "redux/slices/gridSettings/gridSettings.slice";

const useAlertWrapper = () => {
  const [error, setError] = useState<RoleState["error"]>({
    title: "",
    description: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>("error");
  const dispatch = useDispatch<AppDispatch>();

  // Read errors and success from every module.

  const { error: dealershipError, successMessage: dealershipSuccessMessage } =
    useSelector((state: RootState) => state.dealership);

  const { error: assetError, successMessage: assetSuccessMessage } =
    useSelector((state: RootState) => state.asset);

  const {
    error: assetTemplateError,
    successMessage: assetTemplateSuccessMessage,
  } = useSelector((state: RootState) => state.assetTemplate);

  const { error: dispatchError, successMessage: dispatchSuccessMessage } =
    useSelector((state: RootState) => state.dispatch);

  const { error: purchaseError, successMessage: purchaseSuccessMessage } =
    useSelector((state: RootState) => state.purchasing);

  const {
    error: financeError,
    successMessage: financeSuccessMessage,
    depositSuccessMessage,
    convertToDealSuccessMessage,
  } = useSelector((state: RootState) => state.finance);

  const { error: customerError, successMessage: customerSuccessMessage } =
    useSelector((state: RootState) => state.customer);

  const {
    error: personnelError,
    isImportSuccess: userBulkImportSuccess,
    successMessage: personnelSuccessMessage,
  } = useSelector((state: RootState) => state.personnel);

  const { error: rolesError, successMessage: roleSuccessMessage } = useSelector(
    (state: RootState) => state.role
  );

  const { error: timeClockError } = useSelector(
    (state: RootState) => state.timeClock
  );

  const {
    error: gridSettingsError,
    successMessage: gridSettingsSuccessMessage,
  } = useSelector((state: RootState) => state.gridSettings);

  // Check for errors
  useEffect(() => {
    if (dealershipError?.title) setError(dealershipError);
    else if (assetError?.title) setError(assetError);
    else if (assetTemplateError?.title) setError(assetTemplateError);
    else if (dispatchError?.title) setError(dispatchError);
    else if (purchaseError?.title) setError(purchaseError);
    else if (financeError?.title) setError(financeError);
    else if (customerError?.title) setError(customerError);
    else if (personnelError?.title) setError(personnelError);
    else if (rolesError?.title) setError(rolesError);
    else if (timeClockError?.title) setError(timeClockError);
    else if (gridSettingsError?.title) setError(gridSettingsError);

    // TODO: It will be uncommented once all the grid types added into enums from BE
    // else if (gridSettingsError.title) setError(gridSettingsError);
  }, [
    dealershipError,
    assetError,
    assetTemplateError,
    dispatchError,
    purchaseError,
    financeError,
    customerError,
    personnelError,
    rolesError,
    timeClockError,
    gridSettingsError,
  ]);

  // Check for success
  useEffect(() => {
    if (dealershipSuccessMessage) setSuccessMessage(dealershipSuccessMessage);
    else if (assetSuccessMessage) setSuccessMessage(assetSuccessMessage);
    else if (assetTemplateSuccessMessage)
      setSuccessMessage(assetTemplateSuccessMessage);
    else if (dispatchSuccessMessage) setSuccessMessage(dispatchSuccessMessage);
    else if (purchaseSuccessMessage) setSuccessMessage(purchaseSuccessMessage);
    else if (financeSuccessMessage) setSuccessMessage(financeSuccessMessage);
    else if (depositSuccessMessage) setSuccessMessage(depositSuccessMessage);
    else if (convertToDealSuccessMessage)
      setSuccessMessage(convertToDealSuccessMessage);
    else if (customerSuccessMessage) setSuccessMessage(customerSuccessMessage);
    else if (personnelSuccessMessage)
      setSuccessMessage(personnelSuccessMessage);
    else if (roleSuccessMessage) setSuccessMessage(roleSuccessMessage);
    else if (userBulkImportSuccess) setSuccessMessage(userBulkImportSuccess);
    else if (gridSettingsSuccessMessage)
      setSuccessMessage(gridSettingsSuccessMessage);
  }, [
    dealershipSuccessMessage,
    assetSuccessMessage,
    assetTemplateSuccessMessage,
    dispatchSuccessMessage,
    purchaseSuccessMessage,
    financeSuccessMessage,
    depositSuccessMessage,
    convertToDealSuccessMessage,
    customerSuccessMessage,
    personnelSuccessMessage,
    roleSuccessMessage,
    userBulkImportSuccess,
    gridSettingsSuccessMessage,
  ]);

  //   Reset error fields when alert closed
  useEffect(() => {
    if (!open) {
      if (error.title) {
        if (dealershipError?.title) dispatch(resetDealershipError());
        else if (assetError?.title) dispatch(resetInventoryError());
        else if (assetTemplateError?.title) dispatch(resetAssetTemplateError());
        else if (dispatchError?.title) dispatch(resetDispatchError());
        else if (purchaseError?.title) dispatch(resetPurchaseError());
        else if (financeError?.title) dispatch(resetFinanceError());
        else if (customerError?.title) dispatch(resetCustomerError());
        else if (personnelError?.title) dispatch(resetPersonnelError());
        else if (rolesError?.title) dispatch(resetRoleError());
        else if (timeClockError?.title) dispatch(resetTimeClockError());
        else if (gridSettingsError?.title) dispatch(resetGridSettingsError());
        setError({
          title: "",
          description: "",
        });
      }

      if (successMessage) {
        if (dealershipSuccessMessage) dispatch(resetDealershipSuccess());
        else if (assetSuccessMessage) dispatch(resetInventorySuccess());
        else if (assetTemplateSuccessMessage)
          dispatch(resetAssetTemplateSuccess());
        else if (dispatchSuccessMessage) dispatch(resetDispatchSuccess());
        else if (purchaseSuccessMessage) dispatch(resetPurchaseSuccess());
        else if (financeSuccessMessage) dispatch(resetFinanceSuccess());
        else if (depositSuccessMessage) dispatch(resetFinanceSuccess());
        else if (convertToDealSuccessMessage) dispatch(resetFinanceSuccess());
        else if (customerSuccessMessage) dispatch(resetCustomerSuccess());
        else if (personnelSuccessMessage) dispatch(resetPersonnelSuccess());
        else if (roleSuccessMessage) dispatch(resetRoleSuccess());
        else if (gridSettingsSuccessMessage)
          dispatch(resetGridSettingsSuccess());
        setSuccessMessage("");
      }
    }
  }, [open]);

  useEffect(() => {
    setOpen(!!error?.title);
    setSeverity("error");
  }, [error]);

  useEffect(() => {
    if (successMessage) {
      setSeverity("success");
      setOpen(true);

      const timer = setTimeout(() => {
        setOpen(false);
      }, 1000 * SUCCESS_MESSAGE_TIMEOUT);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return { open, setOpen, severity, error, successMessage };
};

export default useAlertWrapper;
