import { createSlice } from "@reduxjs/toolkit";
import { TemplateState } from "./assetTemplates.model";
import {
  createAssetTemplate,
  editAssetTemplate,
  getAllAssetTemplates,
  getAssetTemplateById,
  getAssetTemplateHistoryById,
} from "./assetTemplates.action";
import {
  templatesFulfilled,
  templatesRejected,
  templateByIdFulfilled,
  createTemplateFulfilled,
  editTemplateFulfilled,
  assetTemplateHistoryByIdFulfilled,
} from "./assetTemplates.reducer";

const initialState: TemplateState = {
  isLoading: false,
  successMessage: "",
  error: { title: "", description: "" },
  isSuccess: false,
  createEditIsSuccess: false,
  editSuccessMessage: "",
  assetTemplates: { records: [], totalRecords: 0 },
  assetTemplate: null,
  assetTemplateHistory: null,
};

export const assetTemplatesSlice = createSlice({
  name: "asset",
  initialState: initialState,
  reducers: {
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    resetSuccess: (state) => {
      state.isSuccess = false;
      state.successMessage = "";
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => (
    builder.addCase(getAllAssetTemplates.pending, (state, action) => {
      state.isLoading = true;
      state.assetTemplates.records = [];
      state.assetTemplate = null;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAllAssetTemplates.fulfilled, (state, action) => {
      templatesFulfilled(state, action);
    }),
    builder.addCase(getAllAssetTemplates.rejected, (state, action) => {
      templatesRejected(state, action);
    }),
    builder.addCase(getAssetTemplateById.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(getAssetTemplateById.fulfilled, (state, action) => {
      templateByIdFulfilled(state, action);
    }),
    builder.addCase(getAssetTemplateById.rejected, (state, action) => {
      templatesRejected(state, action);
    }),
    builder.addCase(createAssetTemplate.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
      state.successMessage = "";
    }),
    builder.addCase(createAssetTemplate.fulfilled, (state, action) => {
      createTemplateFulfilled(state, action);
    }),
    builder.addCase(createAssetTemplate.rejected, (state, action) => {
      templatesRejected(state, action);
    }),
    builder.addCase(editAssetTemplate.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.successMessage = "";
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(editAssetTemplate.fulfilled, (state, action) => {
      editTemplateFulfilled(state, action);
    }),
    builder.addCase(editAssetTemplate.rejected, (state, action) => {
      templatesRejected(state, action);
    }),
    builder.addCase(getAssetTemplateHistoryById.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAssetTemplateHistoryById.fulfilled, (state, action) => {
      assetTemplateHistoryByIdFulfilled(state, action);
    }),
    builder.addCase(getAssetTemplateHistoryById.rejected, (state, action) => {
      templatesRejected(state, action);
    })
  ),
});

export const { resetError, resetSuccess, reset } = assetTemplatesSlice.actions;
